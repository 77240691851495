html, body {
  height: 100%;
}

body {
  background-color: #dfff99;
  background-image: url('./assets/pink-3ds-Medium.png');
  background-repeat: no-repeat;
  background-size: 900px;
  background-position: center center;
  backdrop-filter: blur(4px);

}



.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image:
    radial-gradient(at 3% 91%, hsla(33,78%,61%,1) 0px, transparent 50%),
    radial-gradient(at 6% 0%, hsla(42,82%,78%,1) 0px, transparent 50%),
    radial-gradient(at 77% 4%, hsla(108,65%,73%,1) 0px, transparent 50%),
    radial-gradient(at 99% 71%, hsla(164,92%,60%,1) 0px, transparent 50%),
    radial-gradient(at 6% 79%, hsla(320,86%,61%,1) 0px, transparent 50%),
    radial-gradient(at 9% 19%, hsla(209,86%,74%,1) 0px, transparent 50%),
    radial-gradient(at 6% 46%, hsla(166,74%,73%,1) 0px, transparent 50%);
  opacity: 0.75;
}



.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.header {
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  text-align: left;
  color: #000000;
}

.bodyText {
  max-width: 75%;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  color: #000000;
}


.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  background: #FFB800;
  border-radius: 40px;
  color: #000000;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  text-align: center;
  padding: 10px 20px;

}

@media only screen and (max-width: 600px) {
  .header {
    font-size: 30px;
    line-height: 37px;
  }

  .bodyText {
    font-size: 20px;
    line-height: 37px;
  }

  .button {
    font-size: 25px;
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 400px) {
  .header {
    font-size: 30px;
    line-height: 37px;
  }

  .bodyText {
    font-size: 15px;
    line-height: 37px;
  }

  .button {
    font-size: 20px;
    padding: 10px 20px;
  }
}